<template>
  <div class="login">
    <div class="login_content">
      <div class="login_content_text">
        <p>彩仓后台管理</p>
        <p>系统平台</p>
        <p>拥有彩仓 便拥有开启财富的钥匙</p>
      </div>
      <div class="login_content_form_wrap">
        <div class="login_content_form">
          <div class="login_content_form_title">用户登录</div>
          <a-form ref="formRef" :model="form" :rules="rules">
            <a-form-item name="userName">
              <a-input
                v-model:value="form.userName"
                placeholder="请输入用户名/手机号"
              >
              </a-input>
            </a-form-item>
            <a-form-item name="passWord">
              <a-input
                type="password"
                v-model:value="form.passWord"
                placeholder="请输入密码"
              />
            </a-form-item>
            <a-form-item>
              <a-button @click="submit" type="primary">登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <CopyRight v-if="false" />
  </div>
</template>
<script>
import { reactive, toRefs, ref } from "vue";
import { login, isValid } from "../../utils/api";
// import { userInfos} from '../../common/common-data'
import { redirctTo, showMessage } from "../../utils/util";
import CopyRight from "../../components/CopyInfo.vue";
import md5 from "js-md5";
export default {
  name: "login",
  components: { CopyRight },
  setup() {
    const formRef = ref(null);
    // const {setInfo, userInfo} = userInfos()
    const state = reactive({
      form: {
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名/手机号码", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loginData: {},
      permissions: [],
      name: "",
      roles: [],
      navs: [],
    });
    function getPermissions(data) {
      data.forEach((ele) => {
        state.permissions.push(ele.menuId);
      });
    }
    function genData(data) {
      getPermissions(data);
      if (state.permissions && state.permissions.length > 0) {
        redirctTo({ path: "/" });
      }
    }
    async function submit() {
      try {
        const value = await formRef.value.validate();
        if (value.passWord && value.userName) {
          const data = {
            userName: value.userName,
            password: md5(value.passWord),
          };
          const result = await login(data);
          if (isValid(result)) {
            debugger;
            state.loginData = result.data;
            if (result.data.myMenu && result.data.myMenu.length > 0) {
              genData(result.data.myMenu);
            } else {
              showMessage("当前用户无权限，请联系管理员处理");
            }
          }
        }
      } catch (e) {
        console.error(`login ${e}`);
      }
    }
    return {
      ...toRefs(state),
      formRef,
      submit,
      genData,
    };
  },
};
</script>
<style lang="scss" scoped>
body {
  position: relative;
}
.login {
  position: absolute;
  min-width: 995px;
  min-height: 675px;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../../img/login-bg.png") center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  &_content {
    //   height: 0;
    //    width: 80%;
    //   max-width: 825px;
    //   padding-bottom: 50%;
    //   height: 0;
    //   width: 80%;
    //   padding-bottom: 49%;
    width: 825px;
    height: 495px;
    background: url("../../img/login-content-bg.png") center center no-repeat;
    background-size: contain;
    display: flex;
    justify-content: space-between;
    position: relative;
    &_text {
      width: 50%;
      text-align: left;
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      padding: 15px 0 0 30px;
      position: relative;
      p {
        padding: 5px 0;
        margin-bottom: 0;
        &:last-child {
          font-size: 16px;
          opacity: 0.8;
        }
        &:first-child {
          padding-top: 30px;
          &::after {
            position: absolute;
            content: "";
            width: 25px;
            height: 2px;
            background: #fff;
            left: 35px;
            top: 30px;
          }
        }
      }
    }
    &_form_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      position: absolute;
      left: 50%;
    }
    &_form {
      transform: translateY(-80px);
      width: 75%;
      &_title {
        color: #1890ff;
        font-size: 25px;
        margin-bottom: 40px;
        font-weight: 700;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          height: 3px;
          width: 40px;
          background: rgba(#1890ff, 0.7);
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.ant-btn {
  width: 100%;
  border-radius: 5px;
}
.ant-form-item {
  margin-bottom: 5px;
}
input {
  border-radius: 5px;
}
.ant-btn-primary {
  margin-top: 20px;
  padding: 8px;
}
.ant-btn {
  height: 42px;
}
</style>